<template>
  <v-form @submit.prevent="submitResponse">
    <h2 v-if="task.content.config.heading" class="mb4">
      <stemble-latex :content="task.content.config.heading" style="white-space: pre-wrap" />
    </h2>
    <div v-for="(question, index) in task.content.config.questions" :key="question.questionText">
      <p class="body-1 mb-4 mt-4">
        <stemble-latex :content="question.questionText" style="white-space: pre-wrap" />
      </p>
      <s-textarea
        v-model="inputs['answer' + index]"
        outlined
        :counter="question.characterLimit > 0"
        :maxlength="question.characterLimit > 0 ? question.characterLimit : undefined"
      />
    </div>
  </v-form>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'AiMultipleQuestion',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    const inputs = {};
    this.task.content.config.questions.forEach((question, index) => {
      inputs['answer' + index] = '';
    });
    return {
      inputs,
    };
  },
};
</script>
